import { useEffect } from 'react'

const RELOAD_QUERY_KEY = 'reloadedOnce'

export const useChunkLoadErrorHandler = (error: Error) => {
  useEffect(() => {
    if (error.name === 'ChunkLoadError') {
      const searchParams = new URLSearchParams(window.location.search)
      const pathname = window.location.pathname
      if (searchParams.get(RELOAD_QUERY_KEY) !== 'true') {
        window.location.replace(`${pathname}?${RELOAD_QUERY_KEY}=true`)
      }
    }
  }, [error.name])
}
