'use client'

import Image from 'next/image'
import { useEffect } from 'react'

import brandLogo from '@/images/brandLogo.svg'
import unplugged from '@/images/unplugged.svg'
import { countryConfig } from '@/shared/countryConfig'
import { useChunkLoadErrorHandler } from '@/shared/hooks/useChunkLoadErrorHandler'
import type { ErrorWithDigest } from '@/shared/utils/error'
import { logNextError } from '@/shared/utils/error'

const { phone, formattedPhone, email } = countryConfig.support

/**
 * Global error page shown when there is an error in the root layout.
 * This page has minimal dependencies as it needs to work in all cases.
 */
export default function GlobalError({ error }: { error: ErrorWithDigest; reset: () => void }) {
  useEffect(() => {
    logNextError(error)
  }, [error])

  useChunkLoadErrorHandler(error)

  return (
    <html>
      <body style={{ margin: '2rem 1rem' }}>
        <div
          style={{
            fontFamily: 'system-ui, sans-serif',
            color: '#333',
            maxWidth: '52rem',
            margin: '0 auto',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Image src={brandLogo} alt="Fortum logo" style={{ width: '8rem', height: 'auto' }} />
          <h1 style={{ fontWeight: '500' }}>Something went wrong</h1>
          <Image
            src={unplugged}
            alt="Unplugged electrical connection"
            style={{ width: '38rem', height: 'auto', maxWidth: '100%' }}
          />
          <p>Unfortunately, an unexpected error has occurred. Please try again later.</p>
          <p>For urgent situations, please contact us:</p>
          <p style={{ marginBottom: '2rem' }}>
            <div>
              <a style={{ color: '#0F7A5A' }} href={`tel:${phone}`}>
                {formattedPhone}
              </a>
            </div>
            <div>
              <a style={{ color: '#0F7A5A' }} href={`mailto:${email}`}>
                {email}
              </a>
            </div>
          </p>
          <div style={{ color: '#757575' }}>
            {error.message && <p>Message: {error.message}</p>}
            {error.digest && <p>Digest: {error.digest}</p>}
          </div>
        </div>
      </body>
    </html>
  )
}
